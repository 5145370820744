import { Link } from "gatsby"
import React from "react"
import Logo from "../images/logo.svg"

import './header/styles.css'


const Footer = () => (
  <footer className="flex flex-col md:flex-row p-4 justify-between items-center bg-grayDark">
    <div className="justify-center">
      <Link to="/">
        <img className="mb-0" src={Logo} height="50" alt="L&L Build "/>
      </Link>
    </div>
    <div className="flex items-center text-xs">
      <p className="text-white mb-0">© 2020 L&L Build </p>
    </div>
    <nav className="flex flex-col md:flex-row justify-center items-center">
      <Link to="/" activeClassName="active-link">Home</Link>
      <Link to="/about" activeClassName="active-link">About</Link>
      <Link to="/portfolio" activeClassName="active-link">Portfolio</Link>
      <Link to="/contacts" activeClassName="active-link">Contacts</Link>
    </nav>
  </footer>
)


export default Footer
