import { Link } from "gatsby"
import React, {useState } from "react"
import Logo from "../../images/logo.svg"
import './styles.css'

function Header () {
  const [show, setShow] = useState(false)

  return (
  <header className="flex flex-col md:flex-row justify-between">
    <div className="p-6 h-20">
      <Link to="/">
        <img src={Logo} height="70" alt="Landl Build"/>
      </Link>
    </div>
    <button className="menu-button" aria-label="Toggle navigation menu on mobile" onClick={() => setShow(!show)}><i className="gg-menu-right" /></button>
    
    <nav className={show ? "navbar navbar-show" : "navbar navbar-hide"}>
      <button aria-label="Close navigation menu on mobile" className="menu-button" onClick={() => setShow(!show)}><i className="gg-close" /></button>
      <div className="nav-item"><Link className="pr-5 no-underline nav-link" to="/" activeClassName="custom-underline">Home</Link></div>
      <div className="nav-item"><Link className="pr-5 no-underline nav-link" to="/about" activeClassName="custom-underline">About</Link></div>
      <div className="nav-item"><Link className="pr-5 no-underline nav-link" to="/portfolio" activeClassName="custom-underline">Portfolio</Link></div>
      <div className="nav-item"><Link className="pr-5 no-underline nav-link" to="/contacts" activeClassName="custom-underline">Contacts</Link></div>
    </nav>
  </header>
  )
}


export default Header
